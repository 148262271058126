// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PriceVolume_linkWindow__54lKK {\r\n    display: flex;\r\n    background-color: white;\r\n    margin-left: 10px;\r\n    justify-content: left;\r\n    border-radius: 10px;\r\n    margin-top: 20px;\r\n    margin-bottom: 10px;\r\n  }\r\n  .PriceVolume_dateLinks__G3Ha0{\r\n    font-weight: 700;\r\n    margin-bottom: 10px;    \r\n  }\r\n  .PriceVolume_dateLinksSpan__uTnMw{\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    margin-left: 10px;\r\n    color: #000;\r\n  }\r\n  .PriceVolume_dateLinksSpanActive__UqMZO {\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    margin-left: 10px;\r\n    color: #0000b3;\r\n    padding-bottom: 10px;\r\n    border-bottom: 2px solid #0000b3;\r\n  }", "",{"version":3,"sources":["webpack://./src/PostRoi/PriceVolumeTab/PriceVolume.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,oBAAoB;IACpB,gCAAgC;EAClC","sourcesContent":[".linkWindow {\r\n    display: flex;\r\n    background-color: white;\r\n    margin-left: 10px;\r\n    justify-content: left;\r\n    border-radius: 10px;\r\n    margin-top: 20px;\r\n    margin-bottom: 10px;\r\n  }\r\n  .dateLinks{\r\n    font-weight: 700;\r\n    margin-bottom: 10px;    \r\n  }\r\n  .dateLinksSpan{\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    margin-left: 10px;\r\n    color: #000;\r\n  }\r\n  .dateLinksSpanActive {\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n    margin-left: 10px;\r\n    color: #0000b3;\r\n    padding-bottom: 10px;\r\n    border-bottom: 2px solid #0000b3;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWindow": "PriceVolume_linkWindow__54lKK",
	"dateLinks": "PriceVolume_dateLinks__G3Ha0",
	"dateLinksSpan": "PriceVolume_dateLinksSpan__uTnMw",
	"dateLinksSpanActive": "PriceVolume_dateLinksSpanActive__UqMZO"
};
export default ___CSS_LOADER_EXPORT___;
